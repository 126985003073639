import React, { Component } from 'react';
import Modal from './Modal';
import SummaryBoxItem from './SummaryBoxItem';

class SummaryBox extends Component {
	render() {
		const { title, list, namesMap } = this.props;
		return (
			<>
				<div className="summary-box">
					<h2>{ title }</h2>
					<div className="summary-items">
					{
						list ? Object.keys(list)?.map((item) => {
							const itemObj = list[item];
							const total = (itemObj.open + itemObj.done);
							const percent = (itemObj && total > 0) ? Math.floor(itemObj.done * 100 / total) : 0;
							return <SummaryBoxItem key={`${title}_${item}`} summary={itemObj.summary} name={namesMap ? namesMap[item] : item} percent={percent} />;
						}) : null
					}
					</div>
				</div>
			</>
		);
	}
}

export default SummaryBox;
