import React, { Component } from 'react';
import $ from 'jquery';
import {get, set, send, getCreatorId} from './Utils';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null
		};
	}
	
	regSubmit() {
		const { state } = this.props;
		const password = document.querySelector('#password').value?.trim();
		const password2 = document.querySelector('#password2').value?.trim();
		const email = document.querySelector('#email').value?.trim();
		if (!password || (password !== password2)) {
			this.setState({error: 'יש לוודא סיסמא שוב'});
			return;
		}
		if (password && email) {
			send('reg', 'GET', {password, email}, null, this.keepUserDetails.bind(this));
			this.setState({error: null});
		} else {
			this.setState({error: 'יש למלא את כל השדות'});
			return;
		}
	}
	
	keepUserDetails(res) {
		const { updateState } = this.props;
		if (res) {
			if (res.error) {
				this.setState({error: 'שם משתמש קיים  במערכת'});
			} else {
				document.querySelector('#login-error').innerHTML = '';
				if (res.email && res.token && res.id) {
					set('userName', res.email);
					set('token', res.token);
					set('id', res.id);
					updateState({state: 'HOME'});
				}
			}
		}
	}
		
	render() {
		const { error } = this.state;
		return (
			<div id="reg" className="login">
				<div className="bg1" />
				<div className="regField">
					<div className="loginText">
						<div>מערכת שבצק נועדה להקל על ניהול השגים נדרשים ומשימות בזמן אימון יחדיות השדה</div>
						<div>הרישום, הינו ברמת הפלוגה, כלומר, משתמש אחד לכלל הפלוגה</div>
						<div>לכל שאלה ניתן לפנות אל נתנאל חדד בטלפון או בדוא"ל</div>
						<div>0543104507</div>
						<div>netanelhadad@gmail.com</div>
					</div>
					<input type="text" id="email" placeholder="אימייל" />
					<input type="password" id="password" placeholder="סיסמא" />
					<input type="password" id="password2" placeholder="אימות סיסמא" />
					<div id="login-error" className="login-error" />
					<button id="loginBtn" onClick={() => this.regSubmit()}>שלח</button>
				</div>
				{
					error ? ( <div className="login-error">{ error }</div> ) : null
				}
			</div>
		);
	}
}

export default Login;
