import React, { Component } from 'react';
import $ from 'jquery';
import Footer from './Footer';
import Modal from './Modal';
import {get, set, send, getCreatorId} from './Utils';

class Soldiers extends Component {
	constructor(props) {
		super(props);
		this.csvRef = React.createRef();
		this.state = {
			soldiers: get('soldiers') || [],
			professions: get('professions') || [],
			showPopup: false,
			selectedProfessionOption: 'exist',
			editSoldier: null
		};
	}
	componentDidMount() {
		send('get-soldiers', 'GET', {admin: get('id')}, null, (res) => this.onGetSoldiers(res));
	}
	
	addSoldier() {
		const { selectedProfessionOption } = this.state;
		const name = document.querySelector('#name')?.value?.trim() || '';
		const phone = document.querySelector('#phone')?.value?.trim() || '';
		const privateNumber = document.querySelector('#privateNumber')?.value?.trim() || '';
		const arrived = document.querySelector('#arrived')?.checked ? '1' : '0';
		const profession = selectedProfessionOption === 'new' ? document.querySelector('#profession')?.value.trim() : Array.from(document.querySelector('#professionSelect')?.selectedOptions)?.map((option) => option?.value)?.join(',') || '';
		const soldierClass = document.querySelector('#class')?.value?.trim() || '';
		send('add-soldier', 'POST', {name, phone, privateNumber, profession, class: soldierClass, arrived}, null, (res) => {
			this.onGetSoldiers(res);
			this.closePopup();
		});
	}
	
	editSoldier() {
		const { editSoldier, selectedProfessionOption } = this.state;
		const name = document.querySelector('#name')?.value?.trim() || '';
		const phone = document.querySelector('#phone')?.value?.trim() || '';
		const privateNumber = document.querySelector('#privateNumber')?.value?.trim() || '';
		const arrived = document.querySelector('#arrived')?.checked ? '1' : '0';
		const profession = selectedProfessionOption === 'new' ? document.querySelector('#profession')?.value.trim() : Array.from(document.querySelector('#professionSelect')?.selectedOptions)?.map((option) => option?.value)?.join(',') || '';
		const soldierClass = document.querySelector('#class')?.value?.trim() || '';
		const id = editSoldier.id;
		send('edit-soldier', 'POST', {id, name, phone, privateNumber, profession, class: soldierClass, arrived}, null, (res) => {
			this.onGetSoldiers(res);
			this.setState({
				editSoldier: null
			});
		});
	}
	
	removeSoldier() {
		const { editSoldier } = this.state;
		const deleteConfirm = confirm('אתה בטוח שברצונך למחוק את ' + editSoldier.name + '?');
		if (deleteConfirm) {
			send('remove-soldier', 'POST', {id: editSoldier.id}, null, (res) => {
				this.onGetSoldiers(res);
				this.setState({
					editSoldier: null
				});
			});
		}
	}
	
	onGetSoldiers(res) {
		res.sort((a, b) => a.name < b.name);
		set('soldiers', res);
		const professionsMap = {};
		res.forEach((soldier) => { professionsMap[soldier.profession] = true } );
		const professions = Object.keys(professionsMap);
		set('professions', professions);
		this.setState(() => { return {
			soldiers: res,
			professions
		}; });
	}
	
	showAddSoldier() {
		this.setState(() => { return { showPopup: true }; });
	}
	
	closePopup() {
		this.setState(() => { return { showPopup: false }; });
	}
	
	loadFromFile() {
		this.csvRef.current.click();
	}
	
	onFileSelected() {
		const fd = new FormData();
		fd.append('csv', this.csvRef.current.files[0]);
		fd.append('admin', get('id'));
		send('load-soldeirs-csv', 'POST', {}, fd, (res) => this.onGetSoldiers(res));
	}
	
	onProfessionOption(e) {
		const selectedProfessionOption = e?.target?.value;
		this.setState(() => { return { selectedProfessionOption }; });
	}
	
	getAddButtons() {
		return (
			<>
				<input type="button" value="אישור" onClick={() => this.addSoldier()} />
				<input type="button" value="ביטול" onClick={() => this.closePopup()} />
			</>
		);
	}
	
	render() {
		const { updateState } = this.props;
		const { soldiers, showPopup, professions, selectedProfessionOption, editSoldier } = this.state;
		return (
			<>
				<div className="bg1 opacity" />
				<div id="soldiers" className="page">
					<div className="add-buttons">
						<div className="add" onClick={() => this.showAddSoldier()}></div>
						<button className="load-btn" onClick={() => this.loadFromFile()}>טען מקובץ</button>
						<a className="csv-link" href={`${location.protocol}//www.shabtzak.com/חיילים.csv`} target="_blank">הורדת קובץ למילוי</a>
						<input ref={this.csvRef} type="file" name="csv" id="csv" onChange={() => this.onFileSelected()} />
					</div>
					{
						(soldiers?.length) ? (
							<>
								<div className="soldiers-table-header">
									<div className="table-row table-header">
										<div className="table-col">שם</div>
										<div className="table-col">מקצוע</div>
										<div className="table-col">מחלקה</div>
									</div>
								</div>
								<div className="soldiers-table">
									{
										soldiers.map((soldier) => {
											return (
												<div key={`soldier_${soldier.id}`} className="table-row" onClick={() => { this.setState({editSoldier: soldier}) }}>
													<div className="table-col">{soldier.name}</div>
													<div className="table-col">{soldier.profession}</div>
													<div className="table-col">{soldier.class}</div>
												</div>
											)
										})
									}
								</div>
							</>
						) : (
							<>
								<div className="arrow" />
								<div className="add-soldiers-msg">כדי להוסיף חיילים, פשוט תלחץ על כפתור ה +, תמלא פרטים ולחץ על אישור</div>
							</>
						) 
					}
					{
						showPopup ? (
							<Modal title="הוספת חייל" buttons={ this.getAddButtons() }>
								<div className="form">
									<div className="required"><input type="text" id="name" placeholder="שם" /></div>
									<div><input type="text" id="phone" placeholder="טלפון" /></div>
									<div className="profession-options">
										<div className="profession-option">
											<input type="radio" checked={selectedProfessionOption === 'exist'} name="professionOption" value="exist" onChange={(e) => this.onProfessionOption(e)} />
											<span>בחר קיים</span>
										</div>
										<div className="profession-option">
											<input type="radio" checked={selectedProfessionOption === 'new'} name="professionOption" value="new" onChange={(e) => this.onProfessionOption(e)} />
											<span>חדש</span>
										</div>
									</div>
									{
										selectedProfessionOption === 'new' ? <input type="text" id="profession" placeholder="מקצוע" /> : (
											<select id="professionSelect" className="profession-select">
												<option value="-1" disabled select>בחר מקצועות</option>
												{
													professions?.map((profession) => {
														return <option key={`prof_${profession}`} value={profession}>{ profession }</option>
													})
												}
											</select>
										)
									}
									<input type="text" id="class" placeholder="מחלקה" />
									<div><input type="checkbox" id="arrived" defaultChecked={true} /> הגיע לאימון</div>
								</div>
							</Modal>
						) : null
					}
					
					{
						editSoldier ? (
							<div className="popup">
								<div className="popupModal" />
								<div className="popupContent">
									<div className="header">
										עריכת חייל
									</div>
									<div className="form">
										<div className="required"><input defaultValue={editSoldier?.name} type="text" id="name" placeholder="שם" /></div>
										<div>
											<input type="text" id="phone" className={editSoldier?.phone ? 'phone-with-icon' : ''} defaultValue={editSoldier?.phone} placeholder="טלפון" />
											{
												editSoldier?.phone ? <div className="phone-call" onClick={() => { window.open('tel:' + editSoldier.phone);}} /> : null
											}
										</div>
										<div className="profession-options">
											<div className="profession-option">
												<input type="radio" checked={selectedProfessionOption === 'exist'} name="professionOption" value="exist" onChange={(e) => this.onProfessionOption(e)} />
												<span>בחר קיים</span>
											</div>
											<div className="profession-option">
												<input type="radio" checked={selectedProfessionOption === 'new'} name="professionOption" value="new" onChange={(e) => this.onProfessionOption(e)} />
												<span>חדש</span>
											</div>
										</div>
										{
											selectedProfessionOption === 'new' ? <input type="text" id="profession" placeholder="מקצוע" /> : (
												<select id="professionSelect" className="profession-select" defaultValue={editSoldier.profession}>
													<option value="-1" disabled>בחר מקצועות</option>
													{
														professions?.map((profession) => {
															return <option key={`prof_${profession}`} value={profession}>{ profession }</option>
														})
													}
												</select>
											)
										}
										<input type="text" id="class" defaultValue={editSoldier.class} placeholder="מחלקה" />
										<div className="arrived-section"><input type="checkbox" id="arrived" defaultChecked={editSoldier.arrived === '1'} /><span> הגיע לאימון</span></div>
									</div>
									<div className="popupButtons">
										<input type="button" value="אישור" onClick={() => this.editSoldier()} />
										<input type="button" value="ביטול" onClick={() => { this.setState({editSoldier: null}); } } />
										<input type="button" className="error" value="מחק" onClick={() => this.removeSoldier()} />
									</div>
								</div>
							</div>
						) : null
					}
				</div>
				<Footer currentPage="SOLDIERS" updateState={(data) => updateState(data)} />
			</>
		);
	}
}

export default Soldiers;
