import React, { Component } from 'react';
import $ from 'jquery';
import Modal from './Modal';
import {get, set, send, getCreatorId} from './Utils';
import soldiers from './images/soldiers.png';
import missions from './images/missions.png';
import home from './images/home.png';
import actions from './images/missions_to_soldier.png';

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showInfo: false
		};
	}
	getClass(page) {
		const { currentPage } = this.props;
		return currentPage === page ? 'fotter-btn selected-btn' : 'fotter-btn';
	}
	
	onInfo() {
		this.setState(() => { return { showInfo: true }; });
	}
	
	render() {
		const { updateState } = this.props;
		const { showInfo } = this.state;
		return (
			<>
				<div id="footer" className="footer">
					<div className="info-icon" onClick={() => this.onInfo()} />
					<button className={this.getClass('HOME')} onClick={() => updateState({state: 'HOME'})}><img src={home} /></button>
					<button className={this.getClass('SOLDIERS')} onClick={() => updateState({state: 'SOLDIERS'})}><img src={soldiers} /></button>
					<button className={this.getClass('MISSIONS')} onClick={() => updateState({state: 'MISSIONS'})}><img src={missions} /></button>
					<button className={this.getClass('ACTIONS')} onClick={() => updateState({state: 'ACTIONS'})}><img src={actions} /></button>
				</div>
				{
					showInfo && (
						<Modal title="אודותינו" buttons={<input type="button" value="סגור" onClick={() => {this.setState({showInfo: false});}} />}>
							<div>אנחנו שמחים שבחרתם להשתמש במערכת שבצק </div>
							<div>לכל שאלה או בירורים, ניתן לפנות אלינו בטלפון או במייל:</div>
							<div>0543104507</div>
							<div>netanelhadad@gmail.com</div>
						</Modal>
					)
				}
			</>
		);
	}
}

export default Footer;
