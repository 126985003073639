import React, { Component } from 'react';
import $ from 'jquery';
import {get, set, send, getCreatorId} from './Utils';

class Login extends Component {
	loginSubmit() {
		const { state } = this.props;
		const email = document.querySelector('#email').value;
		const password = document.querySelector('#password').value;
		if (email) {
			send('login', 'GET', {email, password}, null, this.keepUserDetails.bind(this));
		}
	}
	
	keepUserDetails(res) {
		const { updateState } = this.props;
		if (res) {
			if (res.error) {
				updateState({error: 'שם משתמש קיים  במערכת'});
			} else {
				document.querySelector('#login-error').innerHTML = '';
				if (res.email && res.token && res.id) {
					set('userName', res.email);
					set('token', res.token);
					set('id', res.id);
					updateState({state: 'HOME'});
				}
			}
		}
	}
	
	goToReg() {
		const { updateState } = this.props;
		updateState({state: 'REG'});
	}
		
	render() {
		return (
			<div id="login" className="login">
				<div className="bg1" />
				<div className="loginField">
					<input type="text" id="email" placeholder="אימייל" />
					<input type="password" id="password" placeholder="סיסמא" />
					<div id="login-error" className="login-error" />
					<button id="loginBtn" onClick={() => this.loginSubmit()}>שלח</button>
					<div className="link reg-link" onClick={() => this.goToReg()}>עדיין לא רשום? הרשם!</div>
				</div>
			</div>
		);
	}
}

export default Login;
