import $ from 'jquery';
const creatorId = location.search.replace('?id=', '');
const externalToken = location.search.replace('?token=', '');
const apiFile = location.protocol + '//shabtzak.com/server/ajax.php';

const getCreatorId = () => {
	return creatorId || localStorage.getItem('shabtzak_creatorId');
};

const getExternalToken = () => {
	return externalToken;
};

const clearStorage = () => {
	return localStorage.clear();
};

const get = (key) => {
	return JSON.parse(localStorage.getItem('shabtzak_' + creatorId + '_' + key));
};

const set = (key, val) => {
	return localStorage.setItem('shabtzak_' + creatorId + '_' + key, JSON.stringify(val));
};

const send = (rest, method, params, data, success) => {
	let url = apiFile + '?method=' + rest + (rest !== 'login' && rest !== 'reg' && rest !== 'get-id-by-token' ? ('&admin=' + get('id')) : '');
	const token = get('token') ? get('token') : getExternalToken();

	if (token) {
		url += ("&token=" + token);
	}

	if (params) {
		const keys = Object.keys(params);
		keys.forEach((key) => {
			url += ('&' + key + '=' + params[key]);
		});
	}
	
	const obj = {
		url,
		method: method || 'GET'
	};
	
	if (data) {
		obj.processData = false;
		obj.contentType = false;
		obj.data = data;
	}
	
	if (success) {
		obj.success = (res) => success(JSON.parse(res));
	}
	
	return $.ajax(obj);
};

export {
	get,
	set,
	send,
	getCreatorId,
	getExternalToken,
	clearStorage
}