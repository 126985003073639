import React, { Component } from 'react';
import $ from 'jquery';
import Footer from './Footer';
import {get, set, send, getCreatorId} from './Utils';

class Missions extends Component {
	constructor(props) {
		super(props);
		this.csvRef = React.createRef();
		this.state = {
			missions: get('missions') || [],
			showPopup: false,
			professions: get('professions') || [],
			selectedProfessionOption: 'exist',
			editMission: null
		};
	}
	componentDidMount() {
		send('get-missions', 'GET', {admin: get('id')}, null, (res) => this.onGetMissions(res));
	}
	
	addMission() {
		const { selectedProfessionOption } = this.state;
		const name = document.querySelector('#name')?.value?.trim() || '';
		const required = document.querySelector('#required')?.checked ?  1 : 0;
		const profession = selectedProfessionOption === 'new' ? document.querySelector('#profession')?.value.trim() : Array.from(document.querySelector('#professionSelect')?.selectedOptions)?.map((option) => option?.value)?.join(',') || '';
		const active = document.querySelector('#activeMission')?.checked ? '1' : '0';
		send('add-mission', 'POST', {name, required, profession, active}, null, (res) => {
			this.onGetMissions(res);
			this.closePopup();
		});
	}
	
	editMission() {
		const { editMission, selectedProfessionOption } = this.state;
		const name = document.querySelector('#name')?.value?.trim() || '';
		const profession = selectedProfessionOption === 'new' ? document.querySelector('#profession')?.value.trim() : Array.from(document.querySelector('#professionSelect')?.selectedOptions)?.map((option) => option?.value)?.join(',') || '';
		const id = editMission.id;
		const active = document.querySelector('#activeMission')?.checked ? '1' : '0';
		send('edit-mission', 'POST', {id, name, profession, active}, null, (res) => {
			this.onGetMissions(res);
			this.setState({
				editMission: null
			});
		});
	}
	
	removeMission() {
		const { editMission } = this.state;
		const deleteConfirm = confirm('אתה בטוח שברצונך למחוק את ' + editMission.name + '?');
		if (deleteConfirm) {
			send('remove-mission', 'POST', {id: editMission.id}, null, (res) => {
				this.onGetMissions(res);
				this.setState({
					editMission: null
				});
			});
		}
	}
	
	onGetMissions(res) {
		res.sort((a, b) => a.name < b.name)
		set('missions', res);
		const professionsMap = {};
		res.forEach((mission) => { professionsMap[mission.profession || 'כללי'] = true } );
		const professions = Object.keys(professionsMap);
		set('professions', professions);
		this.setState(() => { return {
			missions: res,
			professions
		}; });
	}
	
	showAddMission() {
		this.setState(() => { return { showPopup: true }; });
	}
	
	closePopup() {
		this.setState(() => { return { showPopup: false }; });
	}
	
	onProfessionOption(e) {
		const selectedProfessionOption = e?.target?.value;
		this.setState(() => { return { selectedProfessionOption }; });
	}
	
	loadFromFile() {
		this.csvRef.current.click();
	}
	
	onFileSelected() {
		const fd = new FormData();
		fd.append('csv', this.csvRef.current.files[0]);
		fd.append('admin', get('id'));
		send('load-missions-csv', 'POST', {}, fd, (res) => this.onGetMissions(res));
	}
	
	render() {
		const { updateState } = this.props;
		const { missions, showPopup, professions, selectedProfessionOption, editMission } = this.state;
		return (
			<>
				<div className="bg1 opacity" />
				<div id="missions" className="page">
					<div className="add-buttons">
						<div className="add" onClick={() => this.showAddMission()}></div>
						<button className="load-btn" onClick={() => this.loadFromFile()}>טען מקובץ</button>
						<a className="csv-link" href={`${location.protocol}//www.shabtzak.com/משימות.csv`} target="_blank">הורדת קובץ למילוי</a>
						<input ref={this.csvRef} type="file" name="csv" id="csv" onChange={() => this.onFileSelected()} />
					</div>
					{
						(missions?.length) ? (
							<>
								<div className="missions-table-header">
									<div className="table-row table-header">
										<div className="table-col">שם</div>
										<div className="table-col">מקצוע</div>
									</div>
								</div>
								<div className="missions-table">
									{
										missions.map((mission) => {
											return (
												<div key={`mission_${mission.id}`} className="table-row" onClick={() => { this.setState({editMission: mission}) }}>
													<div className="table-col">{mission.name}</div>
													<div className="table-col">{mission.profession}</div>
												</div>
											)
										})
									}
								</div>
							</>
						) : (
							<>
								<div className="arrow" />
								<div className="add-missions-msg">כדי להוסיף משימות, פשוט תלחץ על כפתור ה +, תמלא פרטים ולחץ על אישור</div>
							</>
						) 
					}
					{
						showPopup ? (
							<div className="popup">
								<div className="popupModal" />
								<div className="popupContent">
									<div className="header">
										הוספת משימה
									</div>
									<div className="form">
										<div className="required"><input type="text" id="name" placeholder="שם" /></div>
										<div className="profession-options">
											<div className="profession-option">
												<input type="radio" checked={selectedProfessionOption === 'exist'} name="professionOption" value="exist" onChange={(e) => this.onProfessionOption(e)} />
												<span>בחר קיים</span>
											</div>
											<div className="profession-option">
												<input type="radio" checked={selectedProfessionOption === 'new'} name="professionOption" value="new" onChange={(e) => this.onProfessionOption(e)} />
												<span>חדש</span>
											</div>
										</div>
										{
											selectedProfessionOption === 'new' ? <input type="text" id="profession" placeholder="מקצוע" /> : (
												<select id="professionSelect" className="profession-select" multiple>
													<option value="-1" disabled select>בחר מקצועות</option>
													{
														professions?.map((profession) => {
															return <option key={`prof_${profession}`} value={profession}>{ profession }</option>
														})
													}
												</select>
											)
										}
										<div className="arrived-section"><input type="checkbox" id="activeMission" defaultChecked={true} /><span>פעילה באימון</span></div>
										
									</div>
									<div className="popupButtons">
										<input type="button" value="אישור" onClick={() => this.addMission()} />
										<input type="button" value="ביטול" onClick={() => this.closePopup()} />
									</div>
								</div>
							</div>
						) : null
					}
					{
						editMission ? (
							<div className="popup">
								<div className="popupModal" />
								<div className="popupContent">
									<div className="header">
										עריכת משימה
									</div>
									<div className="form">
										<div className="required"><input defaultValue={editMission?.name} type="text" id="name" placeholder="שם" /></div>
										
										<div className="profession-options">
											<div className="profession-option">
												<input type="radio" checked={selectedProfessionOption === 'exist'} name="professionOption" value="exist" onChange={(e) => this.onProfessionOption(e)} />
												<span>בחר קיים</span>
											</div>
											<div className="profession-option">
												<input type="radio" checked={selectedProfessionOption === 'new'} name="professionOption" value="new" onChange={(e) => this.onProfessionOption(e)} />
												<span>חדש</span>
											</div>
										</div>
										{
											selectedProfessionOption === 'new' ? <input type="text" id="profession" placeholder="מקצוע" /> : (
												<select id="professionSelect" className="profession-select" defaultValue={editMission.profession}>
													<option value="-1" disabled>בחר מקצועות</option>
													{
														professions?.map((profession) => {
															return <option key={`prof_${profession}`} value={profession}>{ profession }</option>
														})
													}
												</select>
											)
										}
										<div className="arrived-section"><input type="checkbox" id="activeMission" defaultChecked={editMission.active === '1'} /><span>פעילה באימון</span></div>
									</div>
									<div className="popupButtons">
										<input type="button" value="אישור" onClick={() => this.editMission()} />
										<input type="button" value="ביטול" onClick={() => { this.setState({editMission: null}); } } />
										<input type="button" className="error" value="מחק" onClick={() => this.removeMission()} />
									</div>
								</div>
							</div>
						) : null
					}
				</div>
				<Footer currentPage="MISSIONS" updateState={(data) => updateState(data)} />
			</>
		);
	}
}

export default Missions;
