import React, { Component } from 'react';
import $ from 'jquery';
import Footer from './Footer';
import Modal from './Modal';
import SummaryBox from './SummaryBox';
import {get, set, send, getCreatorId, clearStorage} from './Utils';

class Home extends Component {
	constructor(props) {
		super(props);
		this.commentsRef = React.createRef();
		this.state = {
			professions: null,
			missions: null,
			classes: null,
			summary: null,
			missionsName: null,
			showEndPracticePopup: false
		};
	}
	componentDidMount() {
		this.getData();
	}
	
	getData() {
		const getSoldiersMissions = send('get-soldiers-missions', 'GET');
		const getMissions = send('get-missions', 'GET', {onlyActive: 1});
		Promise.all([getSoldiersMissions, getMissions]).then((array) => this.onGetAll(array));
	}
	
	onGetAll([res1, res2]) {
		const soldiers = Object.values(JSON.parse(res1));
		const missions = JSON.parse(res2);
		
		let completedTotal = 0;
		let notCompleted = 0;
		const missionsMap = {};
		const professionsMissions = {};
		const professionsMap = {};
		const classesMap = {};
		const summaryMap = {done: 0, open: 0};
		const missionsName = {};
		missions.forEach((curMission) => {
			missionsName[curMission.id] = curMission.name;
			const professionArr = curMission.profession.split(',');
			professionArr.forEach((arrItem) => {
				if(!professionsMissions[arrItem]) {
					professionsMissions[arrItem] = {};
				}
				
				professionsMissions[arrItem][curMission.id] = {done: 0, open: 0, summary: {done: [], open: []}};
			});
			missionsMap[curMission.id] = {done: 0, open: 0, summary: {done: [], open: []}};
		});
		
		soldiers.forEach((curSoldier) => {
			if (!professionsMissions[curSoldier.profession]) {
				professionsMissions[curSoldier.profession] = {};
			}
			const profMissions = Object.keys(professionsMissions[curSoldier.profession]);
			profMissions.forEach((profMission) => {
				const soldierDesc = (`${curSoldier.name} (מח' ${curSoldier.class} - ${curSoldier.profession})`);
				if(!classesMap[curSoldier.class]) {
					classesMap[curSoldier.class] = {done: 0, open: 0};
				}
				if(!professionsMap[curSoldier.profession]) {
					professionsMap[curSoldier.profession] = {done: 0, open: 0};
				}
				if (curSoldier[profMission]) {
					professionsMap[curSoldier.profession].done++
					missionsMap[profMission].done++;
					classesMap[curSoldier.class].done++;
					summaryMap.done++;
					
					missionsMap[profMission].summary.done.push(soldierDesc);
				} else {
					professionsMap[curSoldier.profession].open++;
					missionsMap[profMission].open++;
					classesMap[curSoldier.class].open++;
					summaryMap.open++;
					
					missionsMap[profMission].summary.open.push(soldierDesc);
				}
			});
		});
		
		this.setState(() => { return {
			professions: professionsMap,
			missions: missionsMap,
			classes: classesMap,
			summary: summaryMap,
			missionsName
		}; } );
	}
	endPractice() {
		this.setState(() => { return { showEndPracticePopup: true } });
	}
	logout() {
		clearStorage();
		this.props.updateState({state: 'LOGIN'});
	}
	onEndPracticeSubmit() {
		const datetime = new Date().getTime();
		const comments = this.commentsRef.current.value;
	send('end-practice', 'POST', {datetime, comments}, null, (res) => {
			this.setState(() => { return { showEndPracticePopup: false }; } );
			this.getData();
		});
	}
	getEndPracticeButtons() {
		return (
			<>
				<input type="button" value="ביטול" onClick={() => {this.setState({showEndPracticePopup: false});}} />
				<input type="button" value="אישור" onClick={() => this.onEndPracticeSubmit()} />
			</>
		);
	}
	render() {
		const { updateState } = this.props;
		const { professions, missions, classes, summary, missionsName, showEndPracticePopup } = this.state;
		const summaryPercent = summary ? Math.floor(summary.done * 100 / (summary.open + summary.done)) : null;
		return (
			<>
				<div className="bg1 opacity" />
				<div id="home" className="page">
					<div className="total-box">
						<div className="percentage">
							<span className="percentage1">{summaryPercent}</span>
							<span className="percentage-sign">%</span>
						</div>
						<div>כללי</div>
					</div>
					<div className="end-practice-btn">
						<input type="button" value="סיים אימון" onClick={() => this.endPractice()} />
						<input type="button" value="התנתק" onClick={() => this.logout()} />
					</div>
					<div className="boxes-container">
						<SummaryBox list={missions} namesMap={missionsName} title="משימות" />
						<SummaryBox list={professions} title="מקצועות" />
						<SummaryBox list={classes} title="מחלקות" />
					</div>
				</div>
				{
					showEndPracticePopup && (
						<Modal title="סיכום אימון" buttons={this.getEndPracticeButtons()}>
							<textarea ref={this.commentsRef} placeholder="הקלד כאן כמה לקחים, מסקנות ושאר דברים שכדאי לזכור מהאימון..." />
						</Modal>
					)
				}
				<Footer currentPage="HOME" updateState={(data) => updateState(data)} />
			</>
		);
	}
}

export default Home;
