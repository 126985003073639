import React, { Component } from 'react';
import $ from 'jquery';

class Modal extends Component {
	render() {
		const { title, children, buttons } = this.props;
		return (
			<div className="popup">
				<div className="popupModal" />
				<div className="popupContent">
					<div className="header">
						{ title }
					</div>
					{ children }
					<div className="popupButtons">
						{ buttons }
					</div>
				</div>
			</div>
		);
	}
}

export default Modal;
