import React, { Component } from 'react';
import Actions from './Actions';
import {get, set, send, getExternalToken} from './Utils';

class ExternalActions extends Actions {
	componentDidMount() {
		this.setState(() => { return { showFooter: false }; });
		const token = getExternalToken();
		send('get-id-by-token', 'GET', {token}, null, (res) => {
			if (res.id) {
				set('id', res.id);
				send('get-soldiers-missions', 'GET', {}, null, (res) => this.onSoldierMissionSuccess(res));
				send('get-missions', 'GET', {onlyActive: 1}, null,(res) => { this.setState(() => { return { missions: res.sort((a, b) => a.name - b.name) }; } )});
			}
		});
	}
}

export default ExternalActions;
