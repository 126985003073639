import React, { Component } from 'react';
import $ from 'jquery';
import Footer from './Footer';
import Modal from './Modal';
import {get, set, send, getCreatorId} from './Utils';

class Actions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			soldiers: get('soldiers') || [],
			missions: get('missions') || [],
			filteredMissions: [],
			classes: get('classes') || [],
			selectedMission: null,
			selectedClass: null,
			selectedDone: '-1',
			removedSoldierSign: null,
			editSoldier: null,
			sortedSoldiers: [],
			loading: false,
			showFooter: true,
			searchTerm: '',
			filteredSoldiers: null
		};
	}
	componentDidMount() {
		send('get-soldiers-missions', 'GET', {}, null, (res) => this.onSoldierMissionSuccess(res));
		send('get-missions', 'GET', {onlyActive: 1}, null, (res) => { this.setState(() => { return { missions: res.sort((a, b) => a.name - b.name) }; } )});
	}
	
	onSoldierMissionSuccess(res) {
		const classes = {};
		Object.keys(res)?.map((soldierId) => {
			classes[res[soldierId].class] = true;
		});
		this.setState(() => { return { sortedSoldiers: Object.keys(res),soldiers: res, classes: Object.keys(classes).sort((a, b) => a.name - b.name) }; } );
	}
	
	closePopup() {
		this.setState(() => { return { removedSoldierSign: null }; });
	}
	
	onMissionSelect(selected) {
		const val = selected?.target?.value
		this.setState(() => { return { selectedMission: val }; });
	}
	
	onSearch(e) {
		const { sortedSoldiers, soldiers } = this.state;
		const searchTerm = e?.target?.value || '';
		this.setState(() => { return { searchTerm }; });
		const newarr = searchTerm ? sortedSoldiers?.filter((soldierId) => {
			const soldier = soldiers[soldierId];
			return soldier.name.indexOf(searchTerm) > -1;
		}) : sortedSoldiers;
		this.setState(() => { return { filteredSoldiers: newarr } } );
	}
	
	onClassSelect(selected) {
		const { classes, missions } = this.state;
		const val = selected?.target?.value;
		const updatedMissions = missions?.filter((missionItem) => {
			const profArr = missionItem?.profession.split(',');
			return (profArr.indexOf(val) > -1);
		});
		this.setState(() => { return { selectedClass: val, filteredMissions: updatedMissions }; });
	}
	
	onDoneSelect(selected) {
		const val = selected?.target?.value
		this.setState(() => { return { selectedDone: val }; });
	}
	
	onSelect(id) {
		const { selectedMission, soldiers, missions } = this.state;
		const mission = missions?.find((curMission) => curMission.id === selectedMission);
		if (soldiers[id][selectedMission]) {
			this.setState(() => { return { removedSoldierSign: {soldier: soldiers[id], mission} } } );	
		} else {
			send('add-soldier-mission', 'GET', {soldier: id, mission: selectedMission}, null, (res) => { this.setState(() => { return { soldiers: res }; } )});
		}
	}
	
	removeSoldierMission() {
		const { removedSoldierSign, selectedMission } = this.state;
		send('remove-soldier-mission', 'GET', {soldier: removedSoldierSign.soldier.id, mission: selectedMission}, null, (res) => { this.setState(() => { return { soldiers: res, removedSoldierSign: null }; } )});
	}
	
	goToLink() {
		window.open(`whatsapp://send?text=${location.protocol}//www.shabtzak.com/index.html?token=${get('token')}`);
	}
	
	downloadPdf() {
		this.setState(() => { return { loading: true }; });
		send('create-pdf', 'GET', {}, null, (res) => {
			window.open(location.protocol + '//shabtzak.com' + res.link);
			this.setState(() => { return { loading: false }; });
		});
	}
	
	downloadCsv() {
		this.setState(() => { return { loading: true }; });
		send('create-detailed-csv', 'GET', {}, null, (res) => {
			window.open(location.protocol + '//shabtzak.com' + res.link);
			this.setState(() => { return { loading: false }; });
		});
	}
	
	render() {
		const { updateState } = this.props;
		const {
			missions, soldiers, selectedMission, classes, selectedClass, selectedDone, removedSoldierSign, editSoldier, sortedSoldiers, loading, showFooter, filteredSoldiers, filteredMissions
		} = this.state;
		const mission = missions?.find((curMission) => curMission.id === selectedMission);
		return (
			<>
				<div className="share" onClick={() => this.goToLink()} />
				<div className="pdf" onClick={() => this.downloadPdf()}>PDF</div>
				<div className="csv" onClick={() => this.downloadCsv()}>CSV</div>
				<div className="bg1 opacity" />
				<div id="actions" className="page">
					<div>
						<select className="inline-select" defaultValue="-1" onChange={(selected) => this.onMissionSelect(selected)}>
							<option value="-1" disabled>משימה</option>
							{
								(filteredMissions?.length ? filteredMissions : missions)?.map((mission) => {
									return <option key={`mission_${mission.id}`} value={mission.id}>{mission.name}</option>
								})
							}
						</select>
						<select className="inline-select small" defaultValue="-2" onChange={(selected) => this.onClassSelect(selected)}>
							<option value="-2" disabled>מחלקה</option>
							<option value="-1">כולם</option>
							{
								classes?.map((soldierClass) => {
									return <option key={`class_${soldierClass}`} value={soldierClass}>{soldierClass}</option>
								})
							}
						</select>
						<select className="inline-select small" defaultValue="-2" onChange={(selected) => this.onDoneSelect(selected)}>
							<option value="-2" disabled>סטטוס</option>
							<option value="-1">כולם</option>
							<option value="1">בוצע</option>
							<option value="0">לא בוצע</option>
						</select>
					</div>
					<div>
						<input type="text" placeholder="חפש חייל" onChange={(e) => this.onSearch(e)} />
					</div>
					
					{
						(soldiers) ? (
							<>
								<div className="soldiers-table-header">
									<div className="table-row table-header">
										<div className="table-col fixed-30">בוצע</div>
										<div className="table-col">שם</div>
										<div className="table-col">מקצוע</div>
										<div className="table-col">מחלקה</div>
									</div>
								</div>
								<div className="soldiers-table soldiers-missions-table">
									{
										(filteredSoldiers || sortedSoldiers)?.map((soldierId) => {
											const soldier = soldiers[soldierId];
											const professionsArr = mission?.profession?.split(',');
											const relatedToMission = (!mission?.profession || (mission && professionsArr.indexOf(soldier.profession) > -1));
											const relatedToClass = (!selectedClass || selectedClass === '-1' || (selectedClass && selectedClass === soldier.class));
											const relatedToDone = (selectedDone === '-1' || (selectedDone === '1' && !!soldier[selectedMission]) || (selectedDone === '0' && !soldier[selectedMission]));
											return relatedToMission && relatedToClass && relatedToDone ? (
												<div key={`soldier_${soldier.id}`} className="table-row">
													<div className="table-col fixed-30">
													{
														selectedMission && selectedMission !== '-1' ? <input type="checkbox" checked={!!soldier[selectedMission]} onClick={() => this.onSelect(soldier.id)} /> : null
													}
													</div>
													<div className="table-col" onClick={() => { this.setState({ editSoldier: soldier});}}>{soldier.name}</div>
													<div className="table-col" onClick={() => { this.setState({ editSoldier: soldier});}}>{soldier.profession}</div>
													<div className="table-col" onClick={() => { this.setState({ editSoldier: soldier});}}>{soldier.class}</div>
												</div>
											) : null
										})
									}
								</div>
							</>
						) : null
					}
				</div>
				{
					removedSoldierSign ? (
						<div className="popup">
							<div className="popupModal" />
							<div className="popupContent">
								<div className="header">
									<span>אתה בטוח ש</span>
									<span>{removedSoldierSign.soldier.name}</span>
									<span> לא ביצע את </span>
									<span>{removedSoldierSign.mission.name}?</span>
								</div>
								<div className="popupButtons">
									<input type="button" value="אישור" onClick={() => this.removeSoldierMission()} />
									<input type="button" value="ביטול" onClick={() => this.closePopup()} />
								</div>
							</div>
						</div>
					) : null
				}
				{
					editSoldier ? (
						<Modal title={editSoldier.name} buttons={<input type="button" value="סגור" onClick={() => {this.setState({editSoldier: null});}} />}>
							<div className="soldier-details-container">
								<div className="soldier-details">
									<div className="table-col label">מקצוע</div>
									<div className="table-col">{editSoldier.profession}</div>
								</div>
								<div className="soldier-details">
									<div className="table-col label">מחלקה</div>
									<div className="table-col">{editSoldier.class}</div>
								</div>
								{
									editSoldier.phone ? (
										<div className="soldier-details">
											<div className="table-col label">טלפון</div>
											<div className="table-col" onClick={() => { window.open('tel:' + editSoldier.phone);}}>
												{editSoldier.phone}
												<span className="phone-call" />
											</div>
										</div>
									) : null
								}
							</div>
							<div className="professions-table">
							{
								missions?.map((missionRow) => {
									const professionsArr = missionRow?.profession?.split(',');
									const relatedToMission = (!missionRow?.profession || (missionRow && professionsArr.indexOf(editSoldier.profession) > -1));
									if (!relatedToMission) {
										return;
									}
									return (
										<div className="mission-grid-item">
											<div className="table-col label">{missionRow.name}</div>
											<div className="table-col">{editSoldier[missionRow.id] ? (<span className="v">✔</span>) : (<span className="x">✖</span>)}</div>
										</div>
									);
								})
							}
							</div>
						</Modal>
					) : null
				}
				{
					loading ? (
						<Modal title="מוריד קובץ">
							<div>נא להמתין - מייצר קובץ להורדה...</div>
						</Modal>
					) : null
				}
				{
					showFooter ? <Footer currentPage="ACTIONS" updateState={(data) => updateState(data)} /> : null
				}
			</>
		);
	}
}

export default Actions;
