import React, { Component } from 'react';
import Login from './Login';
import Reg from './Reg';
import Home from './Home';
import Soldiers from './Soldiers';
import Missions from './Missions';
import Actions from './Actions';
import ExternalActoins from './ExternalActoins';
import {get, set, send, getExternalToken} from './Utils';


class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			state: null
		};
	}
	
	componentDidMount() {
		const token = get('token');
		const externalToken = getExternalToken();
		if (externalToken) {
			this.setState({state: 'ExternalActoins'});
			return;
		}
		if (token) {
			const pageIndex = location.href.indexOf('/#') + 1;
			if (pageIndex) {
				const curPage = location.href.slice(pageIndex);
				this.setState({state: curPage?.replace('#', '')});
			} else {
				this.setState({state: 'HOME'});
			}
		} else {
			this.setState({state: 'LOGIN'});
		}
	}
	
	updateState(data) {
		if (data.state) {
			history.pushState(null, null, '#' + data.state);
		}
		this.setState(data);
	}
	
	render() {
		const { state, error } = this.state;
		return (
			<>
				<div className={`logo${(state === 'LOGIN' || state === 'REG') ? '' : ' logo-small'}`} />
				{
					(!state || state === 'LOGIN') ? <Login state={this.state} updateState={(data) => this.updateState(data)} /> : null
				}
				{
					(state === 'REG') ? <Reg state={this.state} updateState={(data) => this.updateState(data)} /> : null
				}
				{
					(state === 'HOME') ? <Home state={this.state} updateState={(data) => this.updateState(data)} /> : null
				}
				{
					(state === 'SOLDIERS') ? <Soldiers state={this.state} updateState={(data) => this.updateState(data)} /> : null
				}
				{
					(state === 'MISSIONS') ? <Missions state={this.state} updateState={(data) => this.updateState(data)} /> : null
				}
				{
					(state === 'ACTIONS') ? <Actions state={this.state} updateState={(data) => this.updateState(data)} /> : null
				}
				{
					(state === 'ExternalActoins') ? <ExternalActoins state={this.state} updateState={(data) => this.updateState(data)} /> : null
				}
			</>
		);
	}
}

export default App;
