import React, { Component } from 'react';
import Modal from './Modal';

class SummaryBoxItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false
		};
	}
	
	render() {
		const { name, percent, summary } = this.props;
		const { showModal } = this.state;
		return (
			<>
				
				<div className="inner-summary-box" onClick={() => {this.setState({showModal: true});}}>
					<h3>{ name }</h3>
					<div className="percentage">
						<span className="percentage1">{percent}</span>
						<span className="percentage-sign">%</span>
					</div>
				</div>
				{
					(showModal && summary) ? (
						<Modal title={`${name} - פירוט`} buttons={<input type="button" value="סגור" onClick={() => {this.setState({showModal: false});}} />}>
							<div className="professions-table">
							{
								summary?.done?.map((doneRow) => {
									return (
										<div key={`${name}_${doneRow}`} className="mission-grid-item">
											<div className="table-col label">{doneRow}</div>
											<div className="table-col"><span className="v">✔</span></div>
										</div>
									);
								})
							}
							{
								summary?.open?.map((openRow) => {
									return (
										<div key={`${name}_${openRow}`} className="mission-grid-item">
											<div className="table-col label">{openRow}</div>
											<div className="table-col"><span className="x">✖</span></div>
										</div>
									);
								})
							}
							</div>
						</Modal>
					) : null
				}
			</>
		);
	}
}

export default SummaryBoxItem;
